








































import { computed, defineComponent, onMounted, ref, useRouter } from '@nuxtjs/composition-api'
import IconFa6SolidXmark from '~icons/fa6-solid/xmark'
import SquareCaretRight from '~icons/fa6-solid/square-caret-right'
import { postForm } from '~/common/utils/request'
import { useFeatureFlag } from '~/composables/featureFlags/useFeatureFlag'

export type PopOverBoxProps = {
  name: string
  x: number
  y: number

  searchLink?: string
  pspLink?: string
}

const PSP_ORIGIN = 'https://www.phosphosite.org/proteinSearchSubmitAction.action'

export default defineComponent({
  components: { IconFa6SolidXmark, SquareCaretRight },
  props: {
    name: {
      type: String,
      required: true,
    },
    x: { type: Number, required: true },
    y: { type: Number, required: true },

    searchLink: {
      type: String,
      default: undefined,
    },
    pspLink: {
      type: String,
      default: undefined,
    },
  },
  emits: ['close'],
  setup(props) {
    const router = useRouter()
    const newSearchUrl = useFeatureFlag('newSearchUrlStructure')

    const popoverBoxRef = ref<HTMLElement>()
    const modalPlacement = computed(() => {
      return {
        left: props.x / 16 + 'rem',
        // Place it 32 pixels above, so we can display highlighted text
        top: (props.y - 32) / 16 + 'rem',
      }
    })

    const popoverPlacement = ref({
      x: props.x,
      y: props.y,
    })

    onMounted(() => {
      // Make sure that popover is not outside of the screen
      const popoverBoxRect = popoverBoxRef.value!.getBoundingClientRect()

      const minX = 0
      const minY = 0
      const maxX = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      const maxY = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

      // Overflow > 0 means that the popover is outside of the screen
      const leftSideOverflow = minX - popoverBoxRect.left
      const rightSideOverflow = popoverBoxRect.left + popoverBoxRect.width - maxX
      const topSideOverflow = minY - popoverBoxRect.top
      const bottomSideOverflow = popoverBoxRect.top + popoverBoxRect.height - maxY

      if (leftSideOverflow > 0) {
        popoverPlacement.value.x += leftSideOverflow
      } else if (rightSideOverflow > 0) {
        popoverPlacement.value.x -= rightSideOverflow
      } else if (topSideOverflow > 0) {
        popoverPlacement.value.y += topSideOverflow
      } else if (bottomSideOverflow > 0) {
        popoverPlacement.value.y -= bottomSideOverflow
      }
    })

    const redirectToSearch = () => {
      if (props.searchLink) {
        // TODO: Send GA
        // TODO: When use new search url structure, use "q" instead of "search"

        router.push({ path: '/browse', query: { [newSearchUrl.value ? 'q' : 'search']: props.searchLink } })
      }
    }

    const redirectToPSPSite = () => {
      if (props.pspLink) {
        // TODO: Send GA

        // .. Delimit the phophosite id with \n
        const pspIds = props.pspLink.replaceAll(';', '\n')

        // TODO: Replace with https://nuxt.com/modules/device
        const isApple =
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/iPad/i)
        postForm(PSP_ORIGIN, { onlyPSPaccessions: 'true', accessionIds: pspIds }, isApple ? '_self' : '_blank')
      }
    }

    return {
      modalPlacement,
      redirectToPSPSite,
      redirectToSearch,
      popoverBoxRef,
      popoverPlacement,
    }
  },
})
