


























































































































import {
  computed,
  defineComponent,
  nextTick,
  onUnmounted,
  PropType,
  ref,
  useContext,
  useMeta,
  watch,
} from '@nuxtjs/composition-api'

import Tabs from '@/components/feature/static-content/Tabs.vue'
import Breadcrumbs from '@/components/global/navigation/Breadcrumbs.vue'
import PathwaysPageTitle from '@/components/feature/static-content/PathwaysPageTitle.vue'
import PopoverBox, { PopOverBoxProps } from '~/components/feature/pathways/PopoverBox.vue'
import { stringOrUndefined } from '~/common/utils/characters'
import PathwayLegends from '@/components/feature/static-content/PathwaysLegends.vue'
import DynamicSvg from '@/components/global/DynamicSvg.vue'
import { emitCustomEvent } from '@/composables/customEventHook'
import JsonRteField from '~/components/global/cms/JsonRteField.vue'

import IconFa6SolidXmark from '~icons/fa6-solid/xmark'
import IconFaSolidMinus from '~icons/fa-solid/minus'
import IconFaSolidPlus from '~icons/fa-solid/plus'
import FaSolidExpandArrowsAlt from '~icons/fa-solid/expand-arrows-alt'
import FaSolidArrowLeft from '~icons/fa-solid/arrow-left'

import { BreadcrumbsData } from '~/types/breadcrumbs'
import type { PathwayDiagram } from '~/modules/contentstack/types'

type PathwayDiagramPageData = {
  pageData: PathwayDiagram
  breadcrumbs: BreadcrumbsData
}

export default defineComponent({
  components: {
    JsonRteField,
    DynamicSvg,
    Breadcrumbs,
    PathwaysPageTitle,
    Tabs,
    IconFa6SolidXmark,
    PopoverBox,
    PathwayLegends,
    IconFaSolidMinus,
    IconFaSolidPlus,
    FaSolidExpandArrowsAlt,
    FaSolidArrowLeft,
  },
  props: {
    data: {
      required: true,
      type: Object as PropType<PathwayDiagramPageData>,
    },
  },
  setup(props) {
    const context = useContext()

    useMeta(() => ({
      meta: [
        {
          name: 'contentfacet',
          content: 'pathway-diagram',
        },
        {
          name: 'formatfacet',
          content: 'Interactive Diagram',
        },
        ...(props.data.pageData.seo_metadata?.map((item) => ({
          name: item?.type!,
          content: item?.value!,
        })) ?? []),
      ],
    }))

    const mediaUrl = context.$config.cst.mediaUrl

    const pathwaySvgUrl = computed(() => props.data.pageData.diagramConnection?.edges?.at(0)?.node?.media_server_id!)
    const pathwaySvgRef = ref<Element>()

    const extendedPathway = ref<string | null>(null)
    const extendedPathwaySvgProperties = ref<{ url: string; topMargin: string | null } | null>(null)
    const extendedPathwaySvgRef = ref<Element>()

    const svgWrapperRef = ref<Element>()
    const popoverBoxProperties = ref<PopOverBoxProps | null>(null)

    // Set up tabs
    const defaultTab = props.data.pageData.devkey!
    const activeTab = ref(defaultTab)
    const allTabs = new Map<
      string,
      { name: string; content: any; pdfLink?: { title: string | undefined; url: string | undefined } }
    >()
    const pdfUrl = props.data.pageData.pdf_link?.href

    allTabs.set(defaultTab, {
      name: 'Pathway description',
      content: props.data.pageData.description?.json,
      pdfLink: {
        title: props.data.pageData.pdf_link?.title!,
        url: props.data.pageData.pdf_link?.href!,
      },
    })

    for (const additionalTab of props.data.pageData.extended_views!) {
      allTabs.set(additionalTab?.devkey!, {
        name: additionalTab?.name!,
        content: additionalTab?.description?.json,
      })
    }

    const tabs = computed(() => {
      const tabs = [
        {
          id: defaultTab,
          title: allTabs.get(defaultTab)?.name!,
        },
      ]

      if (extendedPathway.value) {
        tabs.push({
          id: extendedPathway.value,
          title: allTabs.get(extendedPathway.value)!.name,
        })
      }

      if (props.data.pageData.show_legend) {
        tabs.push({
          id: 'legend',
          title: 'Legends',
        })
      }

      return tabs
    })

    const handleClosePopoverBox = () => {
      popoverBoxProperties.value = null
    }

    const handleOpenPopover = (target: SVGTextContentElement) => {
      const bubbleRect = target.getBoundingClientRect()
      const svgWrapperRect = svgWrapperRef.value!.getBoundingClientRect()

      // Calculate position of box in wrapper
      popoverBoxProperties.value = {
        x: bubbleRect.x - svgWrapperRect.x + bubbleRect.width / 2,
        y: bubbleRect.y - svgWrapperRect.y + bubbleRect.height / 2,
        name: target.textContent ?? '',
        pspLink: stringOrUndefined(target.getAttribute('data-psp')),
        searchLink: stringOrUndefined(target.getAttribute('data-search')),
      }
    }

    const handleCloseExtendedPathway = () => {
      extendedPathway.value = null
      extendedPathwaySvgProperties.value = null
      activeTab.value = defaultTab
    }

    const handleOpenExtendedPathway = (href: string) => {
      extendedPathway.value = href
      const url = props.data.pageData.extended_views?.find((x) => x?.devkey === href)?.diagramConnection?.edges?.at(0)
        ?.node?.media_server_id!

      // On mobile - move pathway image down so that it is aligned with the top of a viewport
      extendedPathwaySvgProperties.value = {
        url,
        topMargin: mobilePathwayViewState.value === 'open' ? window.scrollY.toString() + 'px' : null,
      }

      // On desktop - show pathway image at the top of the section and scroll to it
      if (mobilePathwayViewState.value !== 'open') {
        svgWrapperRef.value?.scrollIntoView(true)
      }

      activeTab.value = href
    }

    const handleNodeClick = (e: Event) => {
      e.preventDefault()

      const target = e.target as SVGTextContentElement
      const href = stringOrUndefined(target.getAttribute('data-href'))
      const page = stringOrUndefined(target.getAttribute('data-page'))

      if (href) {
        // Href should open extended view pathway
        if (href === defaultTab) {
          handleCloseExtendedPathway()
        } else {
          handleOpenExtendedPathway(href)
        }
      } else if (process.client && page) {
        // Page should open up another pathway page
        if (window.location.toString().includes(page)) {
          // Stay on a same page just close overlays
          handleClosePopoverBox()
          handleCloseExtendedPathway()
        } else {
          window.location.replace(page)
        }
      } else {
        // By default open pop-overs for other nodes
        handleOpenPopover(target)
      }
    }

    // Event Listeners stuff
    const addEventListeners = (svgRef: Element) => {
      const bubbles = svgRef.querySelectorAll('.shadow')
      for (let i = 0; i < bubbles.length; i++) {
        bubbles[i].addEventListener('click', handleNodeClick)
      }
    }

    const cleanEventListeners = (svgRef: Element) => {
      const bubbles = svgRef.querySelectorAll('.shadow')
      for (let i = 0; i < bubbles.length; i++) {
        bubbles[i].removeEventListener('click', handleNodeClick)
      }
    }

    const mobilePathwayViewState = ref<'open' | 'close'>('close')

    watch(mobilePathwayViewState, () => {
      // Mobile version should not display layout footer
      emitCustomEvent(mobilePathwayViewState.value === 'open' ? 'hide-footer' : 'show-footer')
      zoomPercentage.value = 100
    })

    onUnmounted(() => {
      emitCustomEvent('show-footer')
    })

    const zoomPercentage = ref(100)
    const zoomStep = 30
    const maxZoom = 350
    const minZoom = 100

    async function zoom(direction: 'in' | 'out') {
      // Hide all popovers and extended pathways
      handleClosePopoverBox()
      handleCloseExtendedPathway()

      // Handle zoom on the mobile version - copied this from the media repository (not idea hot it works)
      zoomPercentage.value += direction === 'in' ? zoomStep : -zoomStep
      zoomPercentage.value = Math.min(maxZoom, Math.max(minZoom, zoomPercentage.value)) // clamp zoom value
      await nextTick()

      // Window dimensions
      const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

      // Subtract top padding
      h -= 3 * 16 // 3rem

      // Scroll left before zoom
      const sl1 = document.getElementById('pathway-node-container')!.scrollLeft

      // Scroll top before zoom
      const st1 = window.scrollY

      // Compute the new scroll left
      const sl2 =
        (sl1 + w / 2) *
          (zoomPercentage.value /
            (direction === 'in' ? zoomPercentage.value - zoomStep : zoomPercentage.value + zoomStep)) -
        w / 2
      const st2 =
        (st1 + h / 2) *
          (zoomPercentage.value /
            (direction === 'in' ? zoomPercentage.value - zoomStep : zoomPercentage.value + zoomStep)) -
        h / 2

      // Scroll to position
      window.scrollTo(sl2, st2)
      document.getElementById('pathway-node-container')!.scrollTo(sl2, 0)
    }

    return {
      svgWrapperRef,
      popoverBoxProperties,
      activeTab,
      pathwaySvgUrl,
      pathwaySvgRef,
      extendedPathwaySvgRef,
      addEventListeners,
      cleanEventListeners,
      pdfUrl,
      allTabs,
      tabs,
      extendedPathwaySvgProperties,
      handleClosePopoverBox,
      handleCloseExtendedPathway,
      mediaUrl,
      mobilePathwayViewState,
      zoom,
      zoomPercentage,
      maxZoom,
      minZoom,
    }
  },
  head: {},
})
