export const postForm = (url: string, fields: Record<string, string>, target?: string) => {
  // dynamically create a form element
  const _form = document.createElement('form')
  _form.method = 'post'
  _form.action = url

  if (target) {
    // will open new window if target set to "_blank"
    _form.setAttribute('target', target)
  }

  Object.keys(fields).forEach((key) => {
    const _input = document.createElement('input')
    _input.setAttribute('type', 'hidden')
    _input.setAttribute('name', key)
    _input.setAttribute('value', fields[key])
    _form.appendChild(_input)
  })

  document.body.appendChild(_form)
  _form.submit()
  document.body.removeChild(_form)
}
