




import { defineComponent } from '@nuxtjs/composition-api'
import PageTitle from '@/components/feature/static-content/PageTitle.vue'

export default defineComponent({
  components: {
    PageTitle,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
  },
})
