






















import { defineComponent, ref, watchEffect, watch, PropType, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<string[] | { id: string; title: string }[]>,
      required: true,
    },
    selectedTab: {
      type: [Number, String],
      default: 0,
    },
  },
  emits: ['tab-changed'],
  setup(props, ctx) {
    const activeTab = ref(props.selectedTab.toString())

    const tabsMap = computed(() => {
      return props.tabs.map((tab: string | { id: string; title: string }, i: number) => {
        if (typeof tab === 'string') {
          return {
            id: i.toString(),
            title: tab,
          }
        }
        return tab
      })
    })

    watchEffect(() => {
      activeTab.value = props.selectedTab.toString()
    })

    watch(activeTab, () => {
      ctx.emit('tab-changed', activeTab.value)
    })

    return {
      activeTab,
      tabsMap,
    }
  },
})
